<footer class="footer-area footer_img">
  <div class="container-fluid">

    <div class="row">
      

      <div class="col-lg-3">
        <h3 style="">QUICK LINKS</h3>
        <p><a href="/" (click)="onClick('home')" style="  color:white;">Home</a></p>
        <p><a href="/about-us" style="  color:white;">About US</a></p>
        <p><a href="/Products" style="  color:white;">Products</a></p>
        <p><a href="/ContactUs" style="  color:white;">Reach&nbsp;Us</a></p>
        <p><a href="/Sitemap" style="  color:white;">Site Map</a></p>

        <br /> <br />
        <ul class="social_links">
          <li><a href="#"><i class="fab fa-facebook-f icon_"></i> </a></li>
          <li><a href="#"><i class="fa-brands fa-twitter icon_"></i></a></li>
          <li><a href="#"><i class="fa-brands fa-instagram icon_"></i></a></li>
          <li><a href="#"><i class="fa-brands fa-linkedin-in icon_"></i></a></li>
        </ul>
      </div>

      <div class="col-lg-5 margin_l_0">
        <h3 style="">OUR SERVICES</h3>
        <p><a href="/MedicinalorSyntheticChemistryServices" style="  color:white;">Medicinal&nbsp;&&nbsp;Synthetic&nbsp;Chemistry&nbsp;Services</a></p>
        <p><a href="/ProcessR&D" style="  color:white;">Process&nbsp;Development</a></p>
        <p><a href="/APIIntermediates" style="  color:white;">API&nbsp;Intermediates</a></p>
        <p><a href="/FineChemicals" style="  color:white;">Fine Chemicals And Pyridine Derivatives Synthesis</a></p>
      </div>

      <div class="col-lg-4 col3 margin_l_1 ">
        <a href="/ContactUs">
          <h3>REACH US</h3>
        </a>
        <p style="color:white;font-size:14px;font-weight:bold"> ALTRAKEM&nbsp;PHARMA&nbsp;LIFESCIENCES&nbsp;PVT.&nbsp;LTD. </p><br />
        <h6 style="color:#fff;font-weight:bold;letter-spacing:0.5px;">Phone</h6>
        <p>
          <a href="tel:+91 9966658228" target="_blank" style="margin-left:0rem;" style="  color:white;"> +91 9966658228 </a><br />
        </p><br />
        <h6 style="color:#fff;font-weight:bold;letter-spacing:0.5px;">Mail</h6>
        <p>
          <a class="callto_act ma_0" href="mailto:sales@altrakem.com " target="_blank" style="margin-left:0rem;color:white;">
            sales@altrakem.com
          </a>
        </p>
        &nbsp;&nbsp;
        <p style="margin-top: -10px;" class="plot">Plot No. 5, Road No-15 CFC Area, IDA-Nacharam, Hyderabad, Telangana-500 076, India.</p>
      </div>


    </div>


    <div class="row container">
      <div class="col-lg-6 footer_l">
        <div><i class="fa-regular fa-copyright "></i>Altrakem All rights reserved</div>
      </div>

      <div class="col-lg-6 footer_r">
        <div>

          <span>Powered by <a href="https://gagri.net/" target="_blank"><img class="gagri2" src="../../../../assets/img/new imgs/GagriNewLogoColor2.webp" alt="Gagri"/></a></span>

        </div>
      </div>
    </div>

    <div class="container col_1">
      <ul class="display">
        <ng-container *ngFor="let item of searchcompany; let i = index">
          <li class="line_1" *ngIf="showAll || i < 10">
            <h5 style="font-size:9px;" [class.truncate-text]="!showAll && i >= 10"><span (click)="ProductInfo(item.Catalog_No)">{{item.ChemicalName}}</span></h5>
          </li>
        </ng-container>
      </ul>
      <span  *ngIf="!showAll && searchcompany.length > 10" (click)="showAll = true"><u style="cursor: pointer;">Read more <img src="../../../../assets/img/new imgs/rightarrow.png" alt="Readmore" style="width:20px;" /></u></span>
      <span  *ngIf="showAll" (click)="showAll = false"><u style="cursor: pointer;">Show less <img src="../../../../assets/img/new imgs/rightarrow.png" style="width:20px;" alt="rightarrow" /></u></span>
    </div>


    
  </div>






</footer>

<!--<app-demo-sidebar></app-demo-sidebar>-->

<ngx-scrolltop></ngx-scrolltop>


