<div class="container-fluid banner_image breadcrumb__overlay">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="breadcrumb_heading_1">
          <div class="banner_heading">Site Map</div>
          <nav aria-label="breadcrumb crumb">
            <ol class="breadcrumb">
              <li class="Home"><a routerLink="/">Home >></a></li>
              <li style="color:white;margin-left:6px;"><a>Site Map</a></li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid ">
  <div class="container cont">
    <div class="row">
      <h2 class="site">Site Map</h2><br /><br />
      <ul class="list_2">
        <li>
          <a routerLink="/home">HOME</a>
        </li>
        <li>About Us</li>
        <ul class="list_1">
          <li>
            <a href="https://altrakem.com/#/about-us">Company Profile</a>

          </li>
          <li>
            <a href="https://altrakem.com/#/vision-mission"> Vision & Mission With Core Values</a>
          </li>
          <li>
            <a href="https://altrakem.com/#/healthy-safet">Environmental, Health And Safety</a>
          </li>
        </ul>
        <li>Products</li>
        <ul class="list_1">
          
          <li><a href="https://altrakem.com/#/Products">API Intermediates</a></li>
          <li> <a href="https://altrakem.com/#/Products">Pyridine Derivative</a></li>
          <li><a href="https://altrakem.com/#/Products">Fine & speciality Chemicals</a></li>
        </ul>
      </ul>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 prodd_2" style="margin-top:2rem;" *ngFor="let item of searchcompany">

        <ul>
          <li class="line_1" >
            <h5 (click)="ProductInfo(item.Catalog_No)">{{item.ChemicalName}}</h5>
          </li>
        </ul>
      </div><br /><br />
        <ul>
          <li>Our Services</li>
          <ul class="list_1">
            <li><a href="https://altrakem.com/#/MedicinalorSyntheticChemistryServices">Medicinal & Synthetic Chemistry Services</a></li>
            <li>
              <a href="https://altrakem.com/#/ProcessR&D"> Process Development </a>
            </li>
            <li><a href="https://altrakem.com/#/APIIntermediates">API Intermediates</a></li>
            <li>
              <a href="https://altrakem.com/#/FineChemicals">Fine Chemicals And Pyridine Derivatives Synthesis</a>
            </li>
          </ul>
          <li><a href="https://altrakem.com/#/Capabilities">Capabilities</a></li>
          <li><a href="https://altrakem.com/#/ContactUs">Contact Now</a></li>
        </ul>
      
    </div>
   </div>
 </div>
      
          




