<div class="container-fluid banner_image mobile_bread">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="banner_heading">Contact Now</div>
        <nav aria-label="breadcrumb">
          <a href="/home">Home >> </a>
          <a href="">Contact Now</a>
        </nav>
      </div>
    </div>
  </div>
</div>

<div class="container bnr">
 
        </div>
    

<div id="contact" class="contact-area container-fluid content_image"style="color:black">
    <div class="container"style="color:black">
        
      <div class="row" style="padding-top:4rem;">
        <div class="col-lg-5 col-md-12"style="color:black">
          <div class="contact-info">
            <div style=" font-size: 21px;font-weight: bold;padding-bottom: 2rem;color:black">Contact Now</div>
            <div class="d-table">

              <div>
                <ul style="color:black;list-style-type:none;">
                  <li style="color:black">
                    <i class="fas fa-map-marker-alt"></i> <span>Reach Us:</span>
                    Altrakem Pharma Lifesciences Pvt. Ltd. Plot No. 5, Road No-15 CFC Area, IDA-Nacharam, Hyderabad, Telangana-500 076, India.
                  </li>
                  <li style="color:black"><i class="far fa-envelope"></i> <a href="mailto:sales@altrakem.com"><span>Email:</span>sales@altrakem.com</a></li>
                  <li style="color:black"><i class="fas fa-phone-alt"></i> <a href="tel:+91 9966658228"><span>Call:</span>+91 9966658228</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-12">
          <div class="contact-form">


            <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
              <div class="row"style="color:black">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group mb-3">
                    <label style="color:black">Name</label>
                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                      <div *ngIf="name.errors.required"style="color:black">Name is required.</div>
                      <div *ngIf="name.errors.minlength"style="color:black">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                      <div *ngIf="name.errors.maxlength"style="color:black">Name is maximum 50 character.</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group mb-3">
                    <label style="color:black">Email</label>
                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group mb-3">
                    <label style="color:black">Subject</label>
                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group mb-3">
                    <label style="color:black">Phone Number</label>
                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <div class="form-group mb-3">
                    <label style="color:black">Message</label>
                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid"style="color:black">Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>


        <div class="row">
          <div class="col-md-12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.633541955586!2d78.5671449141881!3d17.429365506182293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9eaba50b3de1%3A0x3ae134133496a750!2sRd%20Number%2015%2C%20Industrial%20Development%20Area%2C%20Nacharam%2C%20Secunderabad%2C%20Telangana%20500076!5e0!3m2!1sen!2sin!4v1681120042566!5m2!1sen!2sin"class="map" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade;"></iframe>

            
          </div>
        </div>



    </div>
</div>

<style>
  @media only screen and (max-width:600px) {


    .banner_image {
      background-image: url('assets/breadcrubg.jpg');
      background-attachment: initial;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 2rem;
      height: 90px;
    }

      .banner_image a {
        color: white;
        margin-left: 0%;
        font-size: 10px;
      }

    .banner_heading {
      font-family: "Roboto Condensed";
      font-size: 20px;
      color: white;
      font-weight: 600;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      padding: 10px;
      margin-top: 11px;
      margin-left: -24px;
    }
    body {
      overflow-x: hidden;
    }
  }
</style>
