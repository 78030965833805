<div class="topnav top_bar">
  <div class="row">
    <div class="col-lg-3">
      <a href="tel:+91 9966658228" target="_blank" style="color:white;
       font-size:smaller;margin-left: 32%;cursor:pointer;"><i class="fa fa-fw fa-phone"></i>+91 9966658228</a>
    </div>
    <div class="col-lg-3">
      <h1 class="name">Altrakem Pharma Lifesciences Pvt. Ltd.</h1>
    </div>
    <!--<a href="/about-us" title="Learn more about Altrakem Pharma Lifesciences Pvt. Ltd.">
      Altrakem Pharma Lifesciences Pvt. Ltd.
    </a>-->
    <!--<div class="col-lg-3">
      <a href="https://online.flippingbook.com/view/79925745/" target="_blank" style="color:white;">
        <img src="../../../../assets/img/new imgs/banners/firstpage.PNG" width="20px" />
      </a>
    </div>-->

    <div class="col-lg-6">
      <span class="callto_act ma_0" href="mailto:sales@altrakem.com " target="_blank" style="color: white; cursor: pointer; ">
        <i class="fa fa-envelope"
           aria-hidden="true"></i>&nbsp;&nbsp;sales@altrakem.com
      </span>
      &nbsp;&nbsp;
      <a href="https://api.whatsapp.com/send?phone=+91 9966658228&text=&source=&data=" target="_blank" style="color:white;">
        <i class="fa-brands fa-whatsapp"></i>
      </a>&nbsp;&nbsp;

    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light " [class.active]="classApplied"
     ngStickyNav stickyClass="sticky-box-shadow" style="background:white;">

  <div class="container logo-1 ">
    <img src="../../../../assets/img/new imgs/logoaltrakem1.webp" alt="mobi_logo" class="mobi_logo" routerLink="/home" />
    <img src="../../../../assets/img/new imgs/logoaltrakem1.webp" alt="web_logo" class="web_logo" routerLink="/home" />
    <div class="brand">
      <div class="col-lg-6">

      </div>
      <div class="col-lg-6">
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
          <span class="burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
          </span>
        </button>
      </div>
    </div>

    <div class="collapse navbar-collapse navbar_font" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto headd">
        <li class="nav-item"><span class="nav-link active"><a href="/home" title="Visit Home">Home</a></span></li>
        <li class="nav-item dropdown">
          <span class="nav-link dropbtn"><a href="/about-us" class="nav-item" title="About Us">About&nbsp;Us</a></span><br />
          <div class="dropdown-content" style="width: 350%;">
            <a href="/about-us" title="Explore Company Profile">Company Profile</a>
            <a href="/vision-mission" title="Discover Vision & Mission With Core Values">Vision & Mission With Core Values</a>
            <a href="/healthy-safety" title="Learn about Environmental, Health And Safety practices">Environmental, Health And Safety</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <span class="nav-link dropbtn"><a href="/Products" class="nav-item" title="Products">Products</a></span><br />
          <div class="dropdown-content" style="width: 350%;">
            <a href="/Products" title="Explore API Intermediates">API Intermediates</a>
            <a href="/Products" title="Learn about Pyridine Derivative">Pyridine Derivative</a>
            <a href="/Products" title="Discover Fine & Speciality Chemicals">Fine & speciality Chemicals</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <span class="nav-link dropbtn"><a href="/MedicinalorSyntheticChemistryServices" class="nav-item" style="font-size:15px;margin-top:-0.1rem;" title="Our Services">Our&nbsp;Services</a></span><br />
          <div class="dropdown-content centered" style="width: 350%;">
            <a href="/MedicinalorSyntheticChemistryServices" title="Explore Medicinal or Synthetic Chemistry Services">Medicinal & Synthetic Chemistry Services</a>
            <a href="/ProcessR&D" title="Discover Process Development">Process Development </a>
            <a href="/APIIntermediates" title="Learn about API Intermediates">API Intermediates</a>
            <a href="/FineChemicals" title="Explore Fine Chemicals And Pyridine Derivatives Synthesis">Fine Chemicals And Pyridine Derivatives Synthesis</a>
          </div>
        </li>
        <li class="nav-item"><span class="nav-link active"><a href="/Capabilities" title="Capabilities">Capabilities</a></span></li>
        <li class="nav-item"><span class="nav-link active"><a href="/ContactUs" title="Contact Now">Contact&nbsp;Now</a></span></li>
        <li style="margin-top:-5px;">
          <p-autoComplete class="sea" style="padding-left:2%;" placeholder="Search CAS/Chemical Name...!" [(ngModel)]="selectedProduct" [suggestions]="searchprods" (completeMethod)="filterProducts($event)" field="ChemicalName" [dropdown]="false">
            <ng-template let-country pTemplate="item">
              <div class="country-item" (click)="navigatingToSearchedProduct(country)">
                <div>&nbsp;&nbsp;<h6 class="fst" style="margin-bottom: -14px;margin-left:10px;margin-top: -17px;">{{country.ChemicalName}}&nbsp;|&nbsp;{{country.Cas}}&nbsp;|&nbsp;{{country.Catalog_No}}</h6><br /></div>
              </div>
            </ng-template>
          </p-autoComplete>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto for-responsive">
        <li class="nav-item"><span class="nav-link active"><a href="/home" title="Go to Home">Home</a></span></li>
        <li class="nav-item dropdown">
          <span class="nav-link dropbtn"><a href="/about-us" title="Learn more about us">About&nbsp;Us</a></span><br />
          <div class="dropdown-content" style="width: 350%;">
            <a href="/about-us" title="Explore our Company Profile">Company Profile</a>
            <a href="/vision-mission" title="Discover our Vision & Mission">Vision & Mission With Core Values</a>
            <a href="/healthy-safety" title="Explore our Environmental, Health And Safety practices">Environmental, Health And Safety</a>
          </div>
        </li>
        <br />
        <li class="nav-item dropdown">
          <span class="nav-link dropbtn" href="/Products" title="Explore our Products">Products</span><br />
          <div class="dropdown-content" style="width: 350%;">
            <a href="/Products" title="Learn about API Intermediates">API Intermediates </a>
            <a href="/Products" title="Explore Pyridine Derivative">Pyridine Derivative </a>
            <a href="/Products" title="Discover Fine & Speciality Chemicals">Fine & speciality Chemicals </a>
          </div>
        </li>
        <br />
        <li class="nav-item dropdown">
          <span class="nav-link dropbtn" style="font-size:14px;"><a href="/MedicinalorSyntheticChemistryServices" title="Explore Our Services">Our&nbsp;Services</a></span><br />
          <div class="dropdown-content" style="width: 350%;">
            <a href="/APIIntermediates" title="Learn about API Intermediates">API Intermediates</a>
            <a href="/ProcessR&D" title="Discover Process Development">Process Development </a>
            <a href="/MedicinalorSyntheticChemistryServices" title="Explore Medicinal or Synthetic Chemistry Services">Medicinal or Synthetic Chemistry Services</a>
            <a href="/FineChemicals" title="Learn about Fine Chemicals And Pyridine Derivatives Synthesis">Fine Chemicals And Pyridine Derivatives Synthesis</a>
          </div>
        </li>
        <li class="nav-item"><span class="nav-link"><a href="/Capabilities" title="Explore our Capabilities">Capabilities</a></span></li>
        <li class="nav-item"><span class="nav-link"><a href="/ContactUs" title="Contact us now">Contact&nbsp;Now</a></span></li>
        <li>
        </li>
      </ul>
    </div>

  </div>
</nav>
<div class="mobile_search">
  <div class="container">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <p-autoComplete class="sea" placeholder="Search CAS/Chemical Name...!" [(ngModel)]="selectedProduct" [suggestions]="searchprods" (completeMethod)="filterProducts($event)" field="ChemicalName" [dropdown]="false">
          <ng-template let-country pTemplate="item">
            <div (click)="navigatingToSearchedProduct(country)" style="background-color:white;padding:10px">
              <p>{{country.ChemicalName}}&nbsp;|&nbsp;{{country.Cas}}&nbsp;|&nbsp;{{country.Catalog_No}}</p>
            </div>
          </ng-template>
        </p-autoComplete>

      </div>
      <div class="col-lg-2"></div>
    </div>
  </div>
</div>
<br />

<style>
  @media only screen and (max-width:600px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0;
      margin-top: -10px;
    }

    .topnav {
      display: none;
    }

    .mobile_search {
      display: block;
      margin-top: 19%;
      margin-bottom: -8%;
    }
  }
</style>
<script>
  function locationreload() {
    location.reload();

  }
</script>
