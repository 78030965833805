<div class="container-fluid banner_image">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="banner_heading">Environment, Health & Safety</div>
        <nav aria-label="breadcrumb">
          <a href="/home">Home >> </a>
          <a href="">Environment, Health & Safety</a>
        </nav>
      </div>
    </div>
  </div>
</div>

<br />
<div class="container">
  <div class="row">
    <div>
      <img src="../../../assets/img/new imgs/banners/healthy2.jpg" style="border-radius: 20px;" /><br /><br />
    </div>
      <div>
      <h4 style="font-weight: 400; color: #053e8d;">ENVIRONMENT, HEALTH & SAFETY</h4>
      <div>
        <p><span style="font-weight: 400;">High standards and ongoing development are priorities for us in the areas of environmental, health, and safety (EHS). We have a dedicated safety officer in charge of EHS activities in the company.</span></p>
        <p><span style="font-weight: 400;">Our Safety Committee engages in:</span></p>
        <div>
          <ul>
            <li style="color:black;"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Developing and Putting into Practice EHS SOPs</li>
            <li style="color:black;"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Investigating any occurrence, reviewing safety performance, conducting safety audits, and taking the appropriate remedial and preventative action</li>
          </ul>
        </div>
        <p><span style="font-weight: 400;">We consider safety to be extremely important, so we arrange safety and operational training sessions for all the required staff members and conduct practice drills as well.</span></p>
        <p><span style="font-weight: 400;">We conduct routine audits on:</span></p>
        <div>
          <ul>
            <li style="color:black;"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Chemistry Labs</li>
            <li style="color:black;"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i>Hazardous areas</li>
            <li style="color:black;"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i>Night safety</li>
            <li style="color:black;"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i>Fire safety systems</li>
          </ul>
        </div>
        <p><span style="font-weight: 400;">We make sure that dangerous chemicals and materials are separated and disposed of correctly by implementing Standard Operating Procedures (SOPs) for managing hazardous chemical waste. We have also set up proper ventilation and scrubbing systems to manage hazardous reactions. </span></p>
        <br /><br />
      </div>
    </div>
  </div>
</div>

<style>
  @media only screen and (max-width:600px) {
    .banner_image {
      background-image: url('assets/breadcrubg.jpg');
      background-attachment: initial;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 2rem;
      height: 90px;
    }

      .banner_image a {
        color: white;
        margin-left: 0%;
        font-size: 10px;
      }

    .banner_heading {
      font-family: "Roboto Condensed";
      font-size: 10px;
      color: white;
      font-weight: 600;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      padding: 10px;
      margin-top: 11px;
    }
    body {
      overflow-x: hidden;
    }
  }
</style>
