<div class="container-fluid banner_image">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="banner_heading">Vision & Mission With Core Values</div>
        <nav aria-label="breadcrumb">
          <a href="/home">Home >> </a>
          <a href="">Vision & Mission With Core Values</a>
        </nav>
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-top: 24px;">
  <div class="row">
    <img src="../../../assets/img/new imgs/banners/map.png" style="width: 100%;aspect-ratio: auto 1095 / 365;height: 100%;" />
    <div>
      <h4 style="color: #053e8d; margin-top: 22px;">Vision</h4>
      <p>To be acknowledged by all of our stakeholders as a well-known manufacturer of Speciality Chemicals, Pyridine Derivative, Intermediates, and APIs.</p>
      <br />
      <h4 style="color: #053e8d;">Mission</h4>
      <br />
      <ul>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Ensure the continued manufacture of pharmaceuticals of superior quality.</li>

        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Revolutionary production and investigative solutions, establishing and nurturing relationships with clientele. </li>

        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Allocating resources towards developing new products and technologies can generate significant worth.</li>

        <br />
      </ul>
    </div>

    <h4 style="color: #053e8d;">Core Values</h4>

    <div>
      <br />
      <ul>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Quality</li>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Reliability</li>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Integrity</li>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i>Teamwork</li>
        <li style="color:black"><i class="ins-icon-list-icon margin-10px-right display-inline-block position-absolute left-0 fa fa-angle-right" style="color: #053e8d;"></i> Innovation</li>
      </ul><br />
    </div>
  </div>
</div>


<style>
  @media only screen and (max-width:600px) {
    .banner_image {
      background-image: url('assets/breadcrubg.jpg');
      background-attachment: initial;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 2rem;
      height: 90px;
    }

      .banner_image a {
        color: white;
        margin-left: 0%;
      }

    .banner_heading {
      font-family: "Roboto Condensed";
      font-size: 10px;
      color: white;
      font-weight: 600;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      padding: 10px;
      margin-top: 11px;
    }
    body {
      overflow-x: hidden;
    }
  }
</style>





