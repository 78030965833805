
<div class="container-fluid banner_image">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="banner_heading">API Intermediates</div>
        <nav aria-label="breadcrumb">
          <a href="/home">Home >> </a>
          <a href="">API Intermediates</a>
        </nav>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid content_image">
  
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-4" style="padding-top:2rem;">
        <p>
          <strong>  Altrakem </strong>is a key <b>API Intermediates manufacturer & supplier in the global marketplace</b>. We work closely with API manufacturers and custom formulators to ensure that the active pharmaceutical ingredients.We are providing a broad range of<b> quality pharmaceutical materials</b> at very competitive costs.
        </p>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p>
          Working with <b>sophisticated technologies and API manufacturers</b>, we ensure that the intermediates you need are readily available. Our range meets high standard of quality, reliability and effectiveness. Our active pharmaceutical ingredients are used widely by clients worldwide to manufacture a variety of products like <b>pharmaceuticals, food, cosmetics</b>, and more. Contributing to the daily life, the intermediates are pure, result oriented and cost effective in nature.
        </p>
        <br />
        <div style="background:hsla(36deg, 100%, 50%, 0.26);padding:10px;">
          <p style="font-size: 21px;"><b>Area of serve in manufacturing</b></p>
          <p>➣ APIs</p>
          <p>➣ Advanced intermediates</p>
          <p>➣ Key stating material (KSM)</p>
          <p>➣ Raw material starting material (RSM).</p>
        </div>
        <div>
          <a href="/Products" class="arrow">
            Our Products  <img src="../../../../assets/img/new imgs/rightarrow.png" class="learn-more-btn" style="width:20px;" />
          </a>
        </div>
        <br />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <section>
          <img src="../../../assets/img/new imgs/capabilitites.jpg" />
          <article>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </article>
        </section>
        <br />
      </div>
    </div>
  </div>
</div>

<style>
  @media only screen and (max-width:600px) {
    .banner_image {
      background-image: url('assets/breadcrubg.jpg');
      background-attachment: initial;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 2rem;
      height: 90px;
    }

      .banner_image a {
        color: white;
        margin-left: 0%;
      }

    .banner_heading {
      font-family: "Roboto Condensed";
      font-size: 18px;
      color: white;
      font-weight: 600;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      padding: 10px;
      margin-top: 11px;
    }
    body {
      overflow-x: hidden;
    }
  }
</style>
