<div class="container-fluid banner_image mobile_bread">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="banner_heading">Capabilities</div>
        <nav aria-label="breadcrumb">
          <a href="/home">Home >> </a>
          <a href="">Capabilities</a>
        </nav>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid content_image">
  <div class="container">

    <div class="col-lg-12">
      <div class="row" style="padding-top:2rem;">
        <div class="col-lg-2 col-md-1 col-sm-1 col-xs-1 img l_icon card_0">
          <img src="../../../assets/img/new imgs/icons8-547-68.png" class="circle_img" style="width:15rem;" />
        </div>
        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
          <p class="p_text">
            Expert knowledge in process development, scale up to commercialization and adaptation of new processes and technologies.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon card_0">
          <img src="../../../assets/img/new imgs/icons8-microscope-50.png" class="circle_img" />
        </div>
        <div class="col-lg-10 ">
          <p class="p_text">
            R&D Lab with scientist guided by a doctorate in Organic chemistry with rich experience.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon card_0">
          <img src="../../../assets/img/new imgs/icons8-test-tube-64.png" class="circle_img" />
        </div>
        <div class="col-lg-10 ">
          <p class="p_text">
            Small scale (mg) to kilogram, Tonnage Scale manufacturing Various theoretical models have been developed which describe the growth of small businesses.
          </p>
        </div>
      </div>

     
      <div class="row">
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon card_0">
          <img src="../../../assets/img/new imgs/icons8-18-53.png" class="circle_img" />
        </div>
        <div class="col-lg-10">
          <p class="p_text">
            Large scale contract Manufacturing under GMP/ Non GMP with VQ and Audit support On large scale 3rd Party manufacturing facility.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon card_0">
          <img src="../../../assets/img/new imgs/icons8-lab-tool-64.png" class="circle_img" />
        </div>
        <div class="col-lg-10">
          <p class="p_text">
            Synthesis of customized high cost small volume products, which are commercially not available Custom synthesis means then exclusive
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon card_0">
          <img src="../../../assets/img/new imgs/icons8-molecule-50.png" class="circle_img" />
        </div>
        <div class="col-lg-10">
          <p class="p_text">
            Synthesis of compounds on behalf of the customer Capable of handling wide range of reactions and reagents
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 img l_icon card_0">
          <img src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/24/null/external-lab-health-medicine-flatart-icons-outline-flatarticons.png" class="circle_img" />
        </div>
        <div class="col-lg-10">
          <p class="p_text">
            We are capable enough to manufacturing to 1 mg to multi-tone levels with multi step reactions.
          </p>
        </div>
      </div>
      <br />
    </div>
  </div>
</div>



<style>
  @media only screen and (max-width:600px) {


    .banner_image {
      background-image: url('assets/breadcrubg.jpg');
      background-attachment: initial;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 2rem;
      height: 90px;
    }

      .banner_image a {
        color: white;
        margin-left: 0%;
        font-size: 10px;
      }

    .banner_heading {
      font-family: "Roboto Condensed";
      font-size: 20px;
      color: white;
      font-weight: 600;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      padding: 10px;
      margin-top: -5px;
      margin-left: -24px;
    }
    body {
      overflow-x: hidden;
    }
  }
</style>







