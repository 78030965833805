import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;
//import { NgxSitemapGeneratorService } from 'ngx-sitemap-generator';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  template: `
  <h2>Hello {{name}}</h2>
  <a target="_blank" [href]="'file://' + outputPath + '/index.html'">Altrakem</a>
`,
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
  routerSubscription: any;
  outputPath: string = 'www.google.com';
    name: string;


  constructor(private router: Router,
   // private sitemapGenerator: NgxSitemapGeneratorService
  ) {
    this.name = 'Angular2';
    }

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
