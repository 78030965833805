import { Component, OnInit } from '@angular/core';

import { GeneralService } from '../../Services/generalservice.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-advancedweb-page',
  templateUrl: './advancedweb-page.component.html',
  styleUrls: ['./advancedweb-page.component.scss']
})
export class AdvancedwebPageComponent implements OnInit {
    searchcompany: any;

  constructor(public generalService: GeneralService, public router: Router,) { }

  ngOnInit() {
    this.GetProductsBasedOnCategoryID();
  }
  GetProductsBasedOnCategoryID() {
 debugger
    var UploadFile = new FormData();
    UploadFile.append("CategoryID", '');
    var url = "api/Product/GettingProductDetailSitemaps"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      this.searchcompany = data
     


    }, err => {
      //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      // this.spinner.hide();
    });
  }


  ProductInfo(Catalog_No) {
    debugger
    // Remove any existing data from local storage
    localStorage.removeItem('Cattegoryid');
    localStorage.removeItem('CatlogID');

    // Store the new data in local storage
    localStorage.setItem("CatlogID", Catalog_No)
    //localStorage.setItem("cartData", JSON.stringify(this.cartData))
    //localStorage.setItem("Cattegoryid", this.categoryId);
    this.router.navigate(['/Product/' + Catalog_No]);

    // Open the product information page in a new window
    const url = 'https://altrakem.com/#/Product/'+ Catalog_No;
    window.open(url, '_blank');
  }
}
