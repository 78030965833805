<div class="container-fluid banner_image">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="banner_heading">MEDICINAL & SYNTHETIC CHEMISTRY SERVICES</div>
        <nav aria-label="breadcrumb">
          <a href="/home">Home >> </a>
          <a href="">Medicinal & Synthetic Chemistry Services</a>
        </nav>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid content_image">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <br />
        <img src="../../../assets/img/new imgs/banners/Medicinal_synthesis1.jpeg" class="med_img"/>
      </div>
      <div class="col-lg-6">
        <p>
          <b> Altrakem </b> has a dedicated team focused on medicinal chemistry services and have consistently created value for our customers through the supply of novel intermediates and fragments plus supporting in house projects through a flexible range of collaborative R&D Services.
        </p>
        <p>
          Our strong Synthetic, Organic Chemistry and Manufacturing Capabilities, makes us a one-stop-shop for <b> Custom Development and Manufacturing (CDMO) services and emerge </b> as ‘Partner of Choice’ to global Pharmaceutical and Agrochemical customers.
        </p>
        <p>
          With expertise in synthetic organic chemistry, we offer custom synthesis service in <b>scales ranging from milligram to kilogram,as well as FTE service</b>. We have successful track records of providing synthetic chemistry service to lead pharmaceutical and biotech companies .
        </p>

      </div>
    </div><br />
    <div class="row">
      <div class="col-lg-12">

        <h1>Our Medicinal & Synthetic Chemistry Services include</h1>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />

          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              We could offer most convenience , high quality FTE（Full-Time Equivalent and FFS（Fee-for-service）for pharmaceutical and biotech companies, unive- rsity and research institution Fee for Service Custom Synthesis (FFS)
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">

            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              We offers FFS synthesis (2-20 steps) service including lead optimization, process chemistry development, and synthesis of key and advanced intermediates, reference compounds, small focused libraries, metabolites and impurities, etc. FFS projects generally involve various amounts of material (10 mg to 1 Kg). After we get your inquiry, we will give you price quote in 48 hours. Additional benefits for FFS synthesis including:
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">

            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              No Cost Risk: The clients don’t take any risk for FFS project; and only pay the money when the project has been finished.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">

            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Strict Timeline: Before the FFS project starting, we will negotiate a reasonable timeline with the clients. So <b>we will try our best to deliver the compounds before the timeline to meet the clients</b> request.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Full time equivalents (FTEs) We offers Full time equivalents (FTEs) collaboration to our clients, FTE contracts provide our clients consistent and reliable laboratory capacity, and FTE collaboration can help our clients accelerate their drug discovery in a highly cost-effective manner
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Reliable Service: FTE contracts provide our clients consistent and reliable laboratory service. In a one-off situation, timely project start-up maybe hampered by inadequate available personnel.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Flexibility and Responsiveness: The data-driven dynamics of the drug discovery environment often necessitates changes in goals. The FTE approach allows for projects to change without the need to adjust a work order.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Project Execution promptly: There is no time lapse for proposal writing and contract approval process, so projects may be initiated immediately when the chemistry is provided.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              IP Protection: We understand that our customer’s intellectual property is of utmost importance. We take comprehensive measure to safeguard and ensure the confidentially of all projects.
            </p>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</div>




<style>
  @media only screen and (max-width:600px) {
    .banner_image {
      background-image: url('assets/breadcrubg.jpg');
      background-attachment: initial;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 2rem;
      height: 90px;
    }

      .banner_image a {
        color: white;
        margin-left: 0%;
        font-size: 10px;
      }

    .banner_heading {
      font-family: "Roboto Condensed";
      font-size: 11px;
      color: white;
      font-weight: 600;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      padding: 10px;
      margin-top: 11px;
    }
    body {
      overflow-x: hidden;
    }
  }
</style>
