import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../../Services/generalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  searchcompany: any;
  public showAll = false;
  constructor(private viewportScroller: ViewportScroller, public router: Router, public generalService: GeneralService, ) {
    }
  public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

  ngOnInit() {
    this.GetProductsBasedOnCategoryID();
    }


  GetProductsBasedOnCategoryID() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("CategoryID", '');
    var url = "api/Product/GettingProductDetailSitemaps"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      this.searchcompany = data



    }, err => {
      //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      // this.spinner.hide();
    });
  }
  ProductInfo(Catalog_No) {
    debugger
    // Remove any existing data from local storage
    localStorage.removeItem('Cattegoryid');
    localStorage.removeItem('CatlogID');

    // Store the new data in local storage
    localStorage.setItem("CatlogID", Catalog_No)
    //localStorage.setItem("cartData", JSON.stringify(this.cartData))
    //localStorage.setItem("Cattegoryid", this.categoryId);
    this.router.navigate(['/Product/' + Catalog_No]);

    // Open the product information page in a new window
    const url = 'https://altrakem.com/#/Product/' + Catalog_No;
    window.open(url, '_blank');
  }
    onClick1() {
        this.router.navigateByUrl('/Coreservices')

    }
}
