<div class="container-fluid banner_image mobile_bread">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="banner_heading">Company Profile</div>
          <nav aria-label="breadcrumb">
              <a href="/home">Home >> </a>
              <a href="">Company Profile</a>
          </nav>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid content_image"style="margin-bottom:5%;">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12" style="margin-top:2rem;">
        <div class="row">
          <h3 style="color:#053e8d;">About Us</h3><br />
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" alt="Altrakem" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12 abt">
            <p>
              <strong>Altrakem </strong> is a Hyderabad, India based company with a primary focus on offering chemistry services to <b>pharmaceutical companies, biotech entities, academics, bio-materials, and catalysts industry</b>. Altrakem offers custom synthesis of new chemical entities, <b>API intermediates, Pyridine Derivatives </b>,Speciality chemicals reference, standards and specific Impurities from a milligram to kilogram level. In addition, Altrakem provides process research and development for large scale manufacturing with IP & confidentiality.
              <!--</b>-->
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" alt="Altrakem" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Altrakem focus on offering chemistry services to pharmaceutical, biotech, top academia, speciality chemical industries all across the globe. The company  Research facility situated at IDA-Nacharam Industry Area, Hyderabad, Telangana.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" alt="Altrakem" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              We provide Fast, Reliable, Cost effective delivery & multifaceted synthesis includes Custom Synthesis, Fine chemicals, pyridine derivatives, Process Development of Intermediates & Pharma Impurities from mg to kg scale and delivered within the stipulated timelines as per the client requirement.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" alt="Altrakem" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              We have well experienced Scientific & Management Team over the experience of 12 years in the pharmaceutical field.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" alt="Altrakem" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Our principal role is to identify and understand the complete requirement of the client to support them better.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" alt="Altrakem" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              We satisfy the client expectations by supplying the high purity & quality products.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 arrow_icon">
            <img src="../../../assets/img/new imgs/new_alt_logo1.png" alt="Altrakem" style="width:100%;" />
          </div>
          <div class="col-lg-11 col-md-11 col-sm-12 col-xs-12">
            <p>
              Transparent communication regarding projects to our Clients at regular intervals.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
        <img src="../../../assets/img/new imgs/solution-tube.jpg" alt="Altrakem" class="img_1" />
        <br /><br /><br /><br />
        <img src="../../../assets/img/new imgs/LIfe-Sciences-3-1.jpg" alt="Altrakem" class="img_1" />
      </div>
    </div>            <br />

  </div>
</div>

<style>
  @media only screen and (max-width:600px) {
    .banner_image {
      background-image: url('assets/breadcrubg.jpg');
      background-attachment: initial;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 2rem;
      height: 90px;
    }

      .banner_image a {
        color: white;
        margin-left: 0%;
      }
   
    body{
      max-width:100%;
      overflow-x:hidden;
    }
  }
</style>
