<div class="container-fluid banner_image">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="banner_heading">FINE  CHEMICALS  AND PYRIDINE DERIVATIVES  SYNTHESIS</div>
        <nav aria-label="breadcrumb">
          <a href="/home">Home >> </a>
          <a href="">Fine Chemicals And Pyridine Derivatives Synthesis</a>
        </nav>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid content_image">
  <div class="container">
    <div class="row">

      <div class="col-lg-12 col-md-12 col-sm-4">
        <p>Altrakem’s  fine chemical services include a wide range of experience in the synthesis of molecules for agrochemical and pharmaceutical applications.Altrakem’s  fine chemical services include a wide range of experience in the synthesis of molecules for agrochemical and pharmaceutical applications. <b> Manufacturer of Pyridine Compounds is specialized in multi-step synthesis of a wide variety of substituted pyridine compounds. The company has been improving our products processes with continuous commitment since its inception.</b></p>

      </div>

    </div>
    <div class="row" style="padding-top:2rem">
      <div class="col-lg-6 col-md-6 col-sm-4 col4">
        <p>Altrakem’s  provides custom fine chemical manufacturing or traditional organic synthesis in our multipurpose, flexible chemical production plants. Our experience covers a broad range of fine chemicals, including building blocks to intermediates. These complex, pure chemicals are manufactured in large quantities and according to exact specifications for our customers’ intended product applications. Unlike specialty chemicals, fine chemicals are focused on the chemical makeup of the product, rather than the product application. Our technical expertise, updated equipment, and quality control will assure leading quality, in spec, custom fine chemicals. If you need a fine chemical partner, we deliver!</p>
        <div>
          <br />
          <div style="background:hsla(36deg, 100%, 50%, 0.26);padding:10px;">
            <p style="font-size: 21px;"><b>Area of serve in manufacturing</b></p>
            <p>➣ Pyridine Derivatives</p>
            <p>➣ Fine Chemicals</p>
            <p>➣ Speciality Chemicals.</p>

          </div><br />
          <a routerLink="/Products" class="arrow"style="font-weight:bold;">
            Our Products  <img src="../../../../assets/img/new imgs/rightarrow.png" class="learn-more-btn" style="width:20px;" />
          </a>
        </div><br />
      </div>

      <div class="col-lg-6 col-md-6 col-sm-4 col4">
        <img src="../../../assets/img/new imgs/altra1.jpg" class="img" style="width:94%; border-radius: 36px 2px 36px 2px;" />

      </div>
    </div>
  </div>
</div>



<style>
  @media only screen and (max-width:600px) {
    .banner_image {
      background-image: url('assets/breadcrubg.jpg');
      background-attachment: initial;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 2rem;
      height: 90px;
    }

      .banner_image a {
        color: white;
        margin-left: 0%;
        font-size: 10px;
      }

    .banner_heading {
      font-family: "Roboto Condensed";
      font-size: 11px;
      color: white;
      font-weight: 600;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      padding: 10px;
      margin-top: 11px;
    }
    body {
      overflow-x: hidden;
    }
  }
</style>
