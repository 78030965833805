<div class="container-fluid banner_image mobile_bread">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="banner_heading">Products</div>
        <nav aria-label="breadcrumb">
        
          <a href="/">Home>> </a>
            <a class="breadcrumb-item" href="/Products">Products>> </a>
            <a *ngIf="categoryId==1"  aria-current="page">API Intermediates</a>
            <a *ngIf="categoryId==4"  aria-current="page" style="color:white">Pyridine Derivative</a>
            <a *ngIf="categoryId==5"  aria-current="page" style="color:white">Fine & Speciality Chemicals</a>
           
              <!--<a href="tel:09676308014">
                <img src="https://img.icons8.com/ios-filled/30/ffffff/ringer-volume.png" />
              </a>
            </li>-->

         
        </nav>
      </div>
    </div>
  </div>
</div>


<!--Mobile-->
<div class="container mobile_products" style="padding:10px">
  <div class="row ">
    <!--<div class="col-lg-9 " style="margin-top:5%;  border-radius: 7px;">
      <i class="fa fa-search disply_none"></i>&nbsp;
      <p-autoComplete placeholder=" Search with your products....." [(ngModel)]="selectedcompanys" [suggestions]="searchcomp" (completeMethod)="filterTests()"
                      field="ChemicalName" [dropdown]="false" class="lab" (onClear)="clearItem($event)" style="  width: 630px;
  border-radius: 7px; border-color: #0f76a1;">

      </p-autoComplete>
    </div>-->
    <div class="col-lg-12 col3" style="text-align:right;">
      <span class="input-group-text" style="    width: 94%;
    padding: 10px;  margin: 3%;"><b> Products: {{searchcompany.length}}</b></span>

    </div>
  <br />
    <div class="col-lg-3 col-xs-6">
      <p class="lis
         tclass sp active" [class.active]="categoryId ==1" href="#p1" (click)="FilterList(1)">API Intermediates</p>
    </div>
    <div class="col-lg-3 col-xs-6">
      <p class="listclass sp active" [class.active]="categoryId ==4" href="#p4" (click)="FilterList(4)">Pyridine Derivative</p>
    </div>
    <div class="col-lg-3 col-xs-6">
      <p class="listclass sp active" [class.active]="categoryId ==5" href="#p5" (click)="FilterList(5)">Fine & Speciality Chemicals</p>
    </div>

  </div>
</div>

<div class="conatiner-fluid" style="background-color:aliceblue;">

  <div class="container margin_t_3 ">

    <div class="row">

      <div class="list side_menu">
        <div class="col-lg-1 col-md-2 col-sm-12 col-xs-12 ">

          <ul>
            <li class="tab_li sp" [class.active]="categoryId ==1" href="#p1" (click)="FilterList(1)">API Intermediates</li>
            <li class="tab_li sp" [class.active]="categoryId ==4" href="#p4" (click)="FilterList(4)">Pyridine Derivative</li>
            <li class="tab_li sp" [class.active]="categoryId ==5" href="#p5" (click)="FilterList(5)">Fine & Speciality Chemicals</li>
          </ul>

        </div>
        <div>

        </div>
        <div class="col-lg-2 ">
          <p style="margin-top:1rem;">
            <i>
              Disclaimer
            </i> :<br /> Products covered by valid and unexpired patents not offered or supplied for commercial scale.
          <p>The patent should be verified and liability lies with the buyer.</p>
          <p>Products coveded by Patents  only for R&D use only.</p>

        </div>
      </div>

      <div class="col-lg-10 col-md-6 col-sm-12 col-xs-12 product_list_b">
        <div class="button_d_n">

          <br />
          <table style="width:100%;">
            <tr style="width:100%;">
              <td style="width:75%;text-align:right">
                <a href="tel:09676308014">
                  <img src="https://img.icons8.com/ios-filled/25/000000/ringer-volume.png" class="button_d_n" />
                </a>
              </td>

            </tr>
          </table>
        </div>

        <br />
        <br />

        <div class="container web_serach">

          <div class="row ">
            <div class="col-lg-9 " style="  border-radius: 7px;">
              <i class="fa fa-search disply_none"></i>&nbsp;
              <p-autoComplete placeholder=" Search with your products....." [(ngModel)]="selectedcompanys" [suggestions]="searchcomp" (completeMethod)="filterTests()"
                              field="ChemicalName" [dropdown]="false" class="lab" (onClear)="clearItem($event)" style="  width: 630px;
  border-radius: 7px; border-color: #0f76a1;">

              </p-autoComplete>
            </div>
            <div class="col-lg-3 col3" style="text-align:right;">
              <span class="input-group-text" style="height:29px;"><b> Products: {{searchcompany.length}}</b></span>

            </div>
          </div>

        </div><br />


        <div class="row mobileMargin mb-3" style="margin:0 -22px;">
          <br />
          <div class="col-lg-4" *ngFor="let item of searchcompany" style="padding:10px;margin-left:-1%;">
            <div class="card_1" (click)="ProductInfo(item.CategoryName,item.ChemicalName,item.Cas)" style="background-color:white;cursor:pointer;">

              <div class="row">
                <div class="col-lg-5 cat-figure">
                  <div class="demoWrapper ">
                    <div *ngIf="item.Structure != null ||item.Structure != '' ">
                      <img src="{{HomeUrl+item.Structure}}" class="card_image" alt="Altrakem" *ngIf="item.Structure">
                    </div>
                    <div *ngIf=" item.Structure == null || item.Structure == '' ">
                      <img src="../../../assets/alt_logo-.png" class="card_image_1" alt="Altrakem">
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 " style="font-size:13px; width: 57%;">

                  <p class="rca">
                    <a> {{item.Catalog_No}}</a>
                  </p>

                  <div>
                    <b class="" *ngIf="item.Cas">CAS :</b>
                    <span class=""> {{item.Cas}}</span>
                  </div>
                  <div class="card-titlle" style="word-break:break-word">
                    {{item.ChemicalName}}
                  </div>
                
                    <i _ngcontent-fqu-c85="" class="fa fa-arrow-right icon"></i>
                

                </div>
              </div>
            </div>
          </div>


          <br />
        </div>



      </div>

    </div>

  </div>
</div>


<style>


  .a_g99 {
    width: 90%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: normal;
  }

  @media only screen and (max-width:600px) {

    
    .banner_image {
      background-image: url('assets/breadcrubg.jpg');
      background-attachment: initial;
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 2rem;
      height: 90px;
    }

      .banner_image a {
        color: white;
        margin-left: 0%;
        font-size:10px;
      }

    .banner_heading {
      font-family: "Roboto Condensed";
      font-size: 20px;
      color: white;
      font-weight: 600;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      padding: 10px;
      margin-top: 11px;
    }
    body {
      overflow-x: hidden;
    }
  }
</style>

