<div class="container">
  <div class="row">
    <div class="col-lg-6 col-md-12 col-ms-12 col-xs-12">
      <br /><br />
      <h2>Our Certificates</h2>
      <img src="../../../../assets/img/new imgs/iso.webp" class="our" alt="iso"/>
    </div>
    <div class="col-lg-6 col-md-12 col-ms-12 col-xs-12 ">
      <br /><br />
      <h2>Our Brochure</h2>
      <a href="../../../../assets/Flipbook/altrakem brochure final 17-06-23 (1).pdf" target="_blank">
        <img src="../../../../assets/img/newpdfimage.webp" class="our1" alt="PDF"/>
      </a>
    </div>
  </div>
  
    </div>

<br /><br />
