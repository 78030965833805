
<div class="container-fluid margin_0 " style="display: revert; margin-top: 6%; position: relative;">

  
  <div class="container-fluid">


    <div id="demo" class="carousel slide" data-ride="carousel">
      
      <div class="carousel-inner">

        <div class="carousel-item active">
          <img src="../../../../assets/img/new imgs/banners/2.webp" alt="2" />
         

        </div>

        <div class="carousel-item ">
          <img src="../../../../assets/img/new imgs/banners/3_1.webp" alt="3_1"/>
          
        </div>
        <div class="carousel-item">
          <img src="../../../../assets/img/new imgs/banners/1.webp" alt="1" />
          
        </div>

        <div class="carousel-item">
          <img src="../../../../assets/img/new imgs/banners/4.webp" alt="4"/>
          
        </div>
      </div>

    </div>
    <a class="carousel-control-prev controler" href="#demo" data-slide="prev">
      <span class="carousel-control-prev-icon"></span>
    </a>
    <a class="carousel-control-next controler" href="#demo" data-slide="next">
      <span class="carousel-control-next-icon"></span>
    </a>
  </div>
</div>

<div class="container-fluid bg_1" style="background-image: url('../../../../assets/img/new imgs/banners/bg_123.webp'); ">
  <div class="row">
    .
    <div class="container">     
      <div class="row" style="margin-left: -22px;">
        <div class="col-lg-1"></div>
        <div class="col-lg-5 ">
          <div class="card_1 ">
            <img src="../../../../assets/img/new imgs/chemical-security-freepik1170.webp" alt="chemical-security-freepik1170" class="bg1" />
            <h2 class="heading"><span>What We Do</span></h2>
            <p class="site_text">
              <b> Altrakem Pharma Lifesciences Pvt. Ltd. </b>Custom synthesis of <b>NCE's, Niche APIs and their advanced intermediates</b>, from a milligram to multi kilo &tonnage scale. Contract Manufacturing in FFS & FTE Mode. <b>Process Development </b>for API Intermediates, Custom Synthesis of <b>Pyridine Derivatives  building blocks &Speciality chemicals </b>, Large scale contract Manufacturing under GMP / Non GMP.
            </p>

            <a routerLink="/about-us" class="learn-more-btn undercolor">
              Learn More  <img src="../../../../assets/img/new imgs/rightarrow.png" alt="rightarrow" style="width:20px;" />
            </a>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="card_1">
            <img src="../../../../assets/img/new imgs/unnamed.webp" alt="unnamed1" class="bg1" />
            <h2 class="heading">Why <span>&nbsp;Altrakem?</span></h2>
            <p class="site_text">
              <b> Altrakem Pharma Lifesciences Pvt. Ltd. </b> is a Hyderabad, India based company with a primary focus on offering
              chemistry services to pharmaceutical companies, biotech entities, academics, biomaterials, and
              catalysts industry. Altrakem offers custom synthesis, Pyridine Derivatives, Fine chemicals, Speciality chemicals
              Process Development for API Intermediates, Custom Synthesis of Pyridine Derivatives building blocks &Speciality chemicals , Large scale contract Manufacturing under GMP / Non GMP.
            </p>
            <a routerLink="/APIIntermediates" class="learn-more-btn undercolor">
              Our Services  <img src="../../../../assets/img/new imgs/rightarrow.png" alt="rightarrow" style="width:20px;" />

            </a>
          </div>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </div>
</div>




<style>
  .single-box {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 32rem;
    border: 1px solid rgba(10, 116, 148, 0.16);
    border-radius: 20px;
  }

    .single-box:hover {
      background-color: #0baddf;
      color: #fff;
    }
</style>
